/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

  .modalMint{
    margin-left:3%;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  }

 


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  
  .modalMint{
    margin-left:18%;
  }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

 
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
 
}


