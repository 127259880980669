
.leaflet-container {
 
  height: 100vh;
  z-index: 0;
  background-image:url(./assets/water.svg);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

button.xmint-btn {
  font-family:"proxima_nova_rgregular";
  font-size: 16px;
  font-weight: 500;
  margin-top: 12px;
  width: 300px;
   border: 1px solid;
  background: transparent;
  color: white;
  border-color: #26F8FF;
  height: 40px;
  border-radius: 2px;

}

.crossmintParagraph-d1-0-2-5 {
  color: "";
}



button.xmint-btn:hover {
  font-family:"proxima_nova_rgregular";
  font-size: 16px;
  font-weight: 500;
  margin-top: 12px;
  width: 300px;
   border: 1px solid;
  background: #26F8FF;
  color: #0a0519;
  border-color: #26F8FF;
  height: 40px;
  border-radius: 2px;

}


/*
.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  border: 2px solid rgb(119 16 16);
  background-clip: padding-box;
  right: 0;
  position: fixed;
}
*/
.leaflet-control-rotate{
  background:transparent;
  height: 40px;
  width:40px;
  max-width: 40px;

}
.leaflet-control-rotate-toggle{
  
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  border: none;
  
}


.leaflet-touch .leaflet-bar a{
  margin-top: 20%;
}



.leaflet-touch .leaflet-bar a:first-child {
  font-size: 28px;
  border: 1px solid #26F8FF;
  color:#26F8FF;
  background:black;
  border-radius: 2px;
  width: 40px;
  padding-top: 10%;
  height: 40px;
  border-bottom: 1px solid #26F8FF;
}

.leaflet-touch .leaflet-bar a:last-child {
  font-size: 28px;
  border: 1px solid #26F8FF;
  color:#26F8FF;
  background:black;
  border-radius: 2px;
  width: 40px;
  padding-top: 10%;
  height: 40px;
  border-bottom: 1px solid #26F8FF;
}