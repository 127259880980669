
.ant-modal-root .ant-modal-wrap {
z-index:0;
}






body {
  margin: 0;
  font-family:"proxima_nova_rgregular",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.leaflet-div-icon{
  background-color: transparent;
  border: none;
}
.crossmintParagraph-d1-0-2-5 {
  color: "#0a0519";
}

.ant-modal .ant-modal-content {
background-color: #0a0519;
}
.ant-select-selector{
  background-color: white;
}


.ant-drawer .ant-drawer-content {
  
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #0a0519;
  opacity: 0.9;
}





.ant-drawer-body {
  background-color: #0a0519;
  opacity: 0.9;
  color:#fff;
  margin-top: -32px;
  margin-left: -10px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}




.ant-btn-primary {
  background: transparent;
  
  border-color: #26F8FF;
}

.ant-btn-primary:hover {
  background: #26F8FF;
  color: #0a0519;
}

.ant-btn-primary:not(:disabled):hover {
  color: #0a0519;
  background-color: #4fffff;
}

.ant-btn:hover {
 color: #0a0519;
}

.ant-btn.ant-btn-lg{
  border-radius: 2px;
}

.ant-btn-primary:disabled {
  color: white;
}

.ant-radio-button-wrapper{
  background: black;
  color: white;
  border-color: #26F8FF;
  border-radius: 2px;
  width: 150px;
}

.ant-radio-button-wrapper-checked{
  background: black;
  color: white;
  border-color: #26F8FF;
  border-radius: 2px;
  width: 150px;
}

.ant-input-affix-wrapper >input.ant-input {
color: #fff;
background: transparent;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #26F8FF;
  color: black;
}

.ant-radio-button-wrapper:first-child{
  border-color: #26F8FF;
  border-start-start-radius: 80px;
  border-end-start-radius: 80px;
}

.ant-radio-button-wrapper:last-child{
  border-color: #26F8FF;
  border-start-end-radius: 80px;
  border-end-end-radius: 80px;
}

.ant-input-group-wrapper {
border: 1px solid #26F8FF;
border-radius: 8px;

}
.ant-input{
  color:#fff;
  border:0;
  border-color: transparent;
  background-color: transparent;
}

.ant-input:disabled {
  color: white;
}



.ant-input-affix-wrapper{
  border-color: #26F8FF;
 
  color: #fff;
 
}
.ant-input-group-addon{
  color: #fff;
  border: 2px solid #26F8FF;
}

.ant-input-group .ant-input-group-addon:first-child {
  background-color: #26F8FF;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.ant-input-group .ant-input-group-addon:last-child {
  background-color: #26F8FF;
border-top-right-radius: 8px;
border-bottom-right-radius: 8px;
}


.ant-card {
  background-color: #343a40;
  border:1px solid #343a40;
  color:'white';
}

.ant-card:hover {
  background-color: #0A0519;
  border:1px solid #1AADB2;
  transform: scale(1.05);
  opacity: 1.9;
  color:'white';
}

.ant-card .ant-card-meta-description{
  color: #fff; 
  font-weight: 400;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: -5%;
 
}

.ant-card .ant-card-meta-title {
 color: #fff; 
  font-weight: 500;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}



/* width */
::-webkit-scrollbar {
  width: 8px;
  max-height:5px;
 
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 12px grey; 
  border-radius: 2px;
  height: 100%;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: grey; 
  border-radius: 8px;
  border: 1px solid #26F8FF;


}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #26F8FF; 
  border-radius: 8px;
  border: 1px solid #26F8FF;
}


@media only screen and (max-width: 600px) {

  .leaflet-bottom{
    bottom: 10%;
  }
  .loadingSpin{
    color:"#fff";
    font-size: 24px;
  }
.btnText{
font-size:8px;
}
  }

  @media only screen and (min-width: 768px) {
  
    .leaflet-bottom{
      bottom: 2%;
    }
    .loadingSpin{
      color:"#0A0519";
      font-size: 24px;
    }
.btnText{
font-size:14px;
}

  
  }



